import React from 'react';
import PropTypes from 'prop-types';

const PAYMENT_GATEWAY_MAP = {
  upi: {
    src: 'upi.svg',
    alt: 'UPI',
    width: '34',
    height: '12',
  },
  rupay: {
    src: 'rupay.svg',
    alt: 'RuPay',
    width: '59',
    height: '18',
    padding: 4,
  },
  phonepe: {
    src: 'phonepe.svg',
    alt: 'PhonePe',
    width: '36',
    height: '31',
  },
  gpay: {
    src: 'gpay.svg',
    alt: 'Google Pay',
    width: '56',
    height: '24',
  },
  gpayLogo: {
    src: 'gpay-logo.png',
    alt: 'Google Pay',
    width: '120',
    height: '120',
  },
  amazonpay: {
    src: 'amazonpay.svg',
    alt: 'Amazon Pay',
    width: '32',
    height: '32',
  },
  amazonpaylogo: {
    src: 'amazonpaylogo.svg',
    alt: 'Amazon Pay',
    width: '41',
    height: '23',
    padding: 12,
  },
  icici: {
    src: 'icici.svg',
    alt: 'ICICI',
    width: '34',
    height: '36',
    padding: 11,
  },
  iciciFull: {
    src: 'iciciFull.png',
    alt: 'ICICI',
    width: '69',
    height: '20',
  },
  visa: {
    src: 'visa.svg',
    alt: 'VISA',
    width: '58',
    height: '22',
  },
  mastercard: {
    src: 'mastercard.svg',
    alt: 'Mastercard',
    width: '48',
    height: '29',
  },
  maestro: {
    src: 'maestro.svg',
    alt: 'Maestro',
    width: '45',
    height: '27',
  },
  netbanking: {
    src: 'netbanking.svg',
    alt: 'Net Banking',
    width: '24',
    height: '24',
    padding: 13,
  },
  zestmoney: {
    src: 'zestmoney.svg',
    alt: 'Zest Money',
    width: '400',
    height: '400',
    padding: 10,
  },
  zestmoneyPng: {
    src: 'zestmoneyPng.png',
    alt: 'Zest Money',
    width: '116',
    height: '116',
    padding: 7,
  },
  olamoney: {
    src: 'ola-money.png',
    alt: 'Ola Money',
    width: '100',
    height: '100',
    padding: 10,
  },
  epaylater: {
    src: 'epaylater.png',
    alt: 'ePayLater',
    width: '76',
    height: '74',
    padding: 10,
  },
  epaylaterw: {
    src: 'epaylater-w.png',
    alt: 'ePayLater',
    width: '120',
    height: '120',
    padding: 8,
  },
  flexmoney: {
    src: 'flexmoney.png',
    alt: 'Flexmoney',
    width: '146',
    height: '40',
    padding: 2,
  },
  kotak: {
    // src: 'kotak.svg',
    src: 'kotak-img.png',
    alt: 'Kotak',
    width: '40',
    height: '36',
  },
  kotakFull: {
    src: 'kotakFull.png',
    alt: 'Kotak',
    width: '77',
    height: '27',
  },
  hdfc: {
    // src: 'hdfc.svg',
    // width: '37',
    // height: '37',
    src: 'hdfc.png',
    width: '32',
    height: '32',
    padding: 11,
    alt: 'HDFC',
  },
  paypal: {
    src: 'paypal.svg',
    alt: 'PayPal',
    width: '22',
    height: '26',
  },
  paytm: {
    src: 'paytm.svg',
    alt: 'Paytm',
    width: '44',
    height: '14',
  },
  dinersclub: {
    src: 'dinersclub.svg',
    alt: 'Diners Club',
    width: '40',
    height: '34',
    padding: 10,
  },
  amex: {
    src: 'amex.svg',
    alt: 'Amex',
    width: '53',
    height: '53',
  },
  amexFull: {
    src: 'amexFull.png',
    alt: 'Amex',
    width: '160',
    height: '48',
  },
  yesbank: {
    src: 'yes-bank.png',
    alt: 'Yes Bank',
    width: '98',
    height: '30',
  },
  indusindbank: {
    src: 'indusind-bank.png',
    alt: 'Indusind Bank',
    width: '98',
    height: '30',
  },
  indusindbankc: {
    src: 'indusind-bank-c.png',
    alt: 'Indusind Bank',
    width: '184',
    height: '148',
  },
  rblbank: {
    src: 'rbl-bank.png',
    alt: 'RBL Bank',
    width: '80',
    height: '84',
  },
  rblFull: {
    src: 'rblFull.png',
    alt: 'RBL Bank',
    width: '62',
    height: '21',
  },
  kreditbee: {
    src: 'kreditbee.png',
    alt: 'KreditBee',
    width: '77',
    height: '77',
  },
  kreditbeeLogo: {
    src: 'kreditbeeLogo.png',
    alt: 'KreditBee',
    width: '102',
    height: '102',
  },
  zestLogo: {
    src: 'zest-logo.png',
    alt: 'Zest',
    width: '97',
    height: '29',
  },
  sbi: {
    src: 'sbi.png',
    alt: 'SBI',
    width: '60',
    height: '60',
  },
  axis: {
    src: 'axis.png',
    alt: 'Axis Bank',
    width: '54',
    height: '54',
    transform: 'translateY(-5%)',
  },
  bob: {
    src: 'bob.png',
    alt: 'Bank of Bank',
    width: '62',
    height: '50',
  },
  citi: {
    src: 'citi.png',
    alt: 'CITI',
    width: '66',
    height: '39',
  },
  hsbc: {
    src: 'hsbc.png',
    alt: 'HSBC',
    width: '69',
    height: '38',
  },
  rbl: {
    src: 'rbl.png',
    alt: 'RBL',
    width: '44',
    height: '56',
    padding: 12,
  },
  standardchartered: {
    src: 'standardchartered.png',
    alt: 'Standard Chartered',
    width: '63',
    height: '84',
    padding: 12,
  },
  au: {
    src: 'au.png',
    alt: 'AU Small Finance Bank',
    width: '96',
    height: '96',
    padding: '0',
  },
  auB: {
    src: 'au-b.png',
    alt: 'AU Small Finance Bank',
    width: '90',
    height: '90',
    padding: '0',
  },
  lazypay: {
    src: 'lazypay.png',
    alt: 'LazyPay',
    width: '94',
    height: '64',
  },
  lazypaym: {
    src: 'lazypaym.png',
    alt: 'LazyPay',
    width: '152',
    height: '113',
  },
  olamoneym: {
    src: 'ola-money-m.png',
    alt: 'Ola Money',
    width: '84',
    height: '57',
  },
  epayretail: {
    src: 'epayretail.png',
    alt: 'ePay',
    width: '102',
    height: '44',
  },
  simpl: {
    src: 'simpl.png',
    alt: 'Simpl',
    width: '72',
    height: '72',
  },
  idfc: {
    src: 'idfc.svg',
    alt: 'IDFC',
    width: '48',
    height: '48',
    padding: 10,
  },
  federalbank: {
    src: 'federalbank.png',
    alt: 'Federal Bank',
    width: '88',
    height: '88',
    padding: 10,
  },
  freecharge: {
    src: 'freecharge.png',
    alt: 'Freecharge',
    width: '172',
    height: '172',
    padding: 10,
  },
  olamoneypostpaid: {
    src: 'olamoneypostpaid.png',
    alt: 'Ola money postpaid',
    width: '107',
    height: '29',
  },
  manymore: {
    src: 'manymore.svg',
    alt: 'manymore',
    width: '43',
    height: '30',
  },
  homecredit: {
    src: 'homecredit.png',
    alt: 'Home Credit',
    width: '111',
    height: '69',
  },
};
const PaymentGateway = ({
  type,
  imgWrapperClassName,
  className,
  text,
  width,
  height,
  textSize,
  border,
  backgroundWhite,
  borderStroke,
  disableName,
  grayscale,
  padding,
}) => (
  <div className={`flex flex-col items-center max-w-[90px] ${className}`}>
    <div
      className={`relative rounded-full flex items-center imgWrapperClassName ${imgWrapperClassName} ${
        border ? 'border' : ''
      } ${borderStroke ? 'border-cf-stroke' : ''}`}
      style={{
        padding:
          padding !== '' ? padding : PAYMENT_GATEWAY_MAP[type]?.padding || 8,
        width,
        height,
        background: backgroundWhite ? 'white' : '',
      }}>
      <img
        className={`w-full ${PAYMENT_GATEWAY_MAP[type].className || ''} ${
          grayscale ? 'grayscale' : ''
        }`}
        loading="lazy"
        alt={text || PAYMENT_GATEWAY_MAP[type].alt || ''}
        width={PAYMENT_GATEWAY_MAP[type].width}
        height={PAYMENT_GATEWAY_MAP[type].height}
        style={
          PAYMENT_GATEWAY_MAP[type]?.transform
            ? { transform: PAYMENT_GATEWAY_MAP[type]?.transform }
            : null
        }
        src={`/img/gateway-icons/${PAYMENT_GATEWAY_MAP[type].src}`}
      />
    </div>
    {!disableName && (
      <div className={`mt-[8px] text-center ${textSize}`}>
        {text || PAYMENT_GATEWAY_MAP[type].alt}
      </div>
    )}
  </div>
);

PaymentGateway.defaultProps = {
  type: 'upi',
  className: '',
  imgWrapperClassName: '',
  textSize: 'text-tiny',
  padding: '',
  text: null,
  backgroundWhite: false,
  borderStroke: false,
  disableName: false,
  grayscale: false,
  border: true,
  width: '48px',
  height: '48px',
};

PaymentGateway.propTypes = {
  type: PropTypes.oneOf([
    'upi',
    'rupay',
    'phonepe',
    'gpay',
    'gpayLogo',
    'amazonpay',
    'amazonpaylogo',
    'icici',
    'visa',
    'mastercard',
    'maestro',
    'netbanking',
    'zestmoney',
    'zestmoneyPng',
    'olamoney',
    'epaylater',
    'epaylaterw',
    'flexmoney',
    'kotak',
    'hdfc',
    'paypal',
    'paytm',
    'dinersclub',
    'amex',
    'amexFull',
    'yesbank',
    'indusindbank',
    'indusindbankc',
    'rblbank',
    'kreditbee',
    'kreditbeeLogo',
    'zestLogo',
    'sbi',
    'axis',
    'bob',
    'citi',
    'hsbc',
    'rbl',
    'standardchartered',
    'au',
    'auB',
    'lazypay',
    'lazypaym',
    'olamoneym',
    'epayretail',
    'simpl',
    'idfc',
    'federalbank',
    'freecharge',
    'kotakFull',
    'iciciFull',
    'rblFull',
    'olamoneypostpaid',
    'manymore',
    'homecredit',
  ]),
  className: PropTypes.string,
  imgWrapperClassName: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string,
  textSize: PropTypes.string,
  backgroundWhite: PropTypes.bool,
  border: PropTypes.bool,
  borderStroke: PropTypes.bool,
  text: PropTypes.string,
  disableName: PropTypes.bool,
  grayscale: PropTypes.bool,
};
export default PaymentGateway;
