import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import PaymentGateway from '../../common/PaymentGateway/PaymentGateway';
import './PaymentGatewaySlider.scss';

/** Use shouldUnmount if used inside Tabs, to avoid fast scroll on mount */

function PaymentGatewaySlider({
  data,
  sameSize,
  backgroundWhite,
  disableName,
  settings,
  className,
  paymentGatewayClass,
  borderStroke,
}) {
  return (
    <div
      className={`paymentgateway-slider overflow-hidden ${
        (sameSize && 'sameSize') || ''
      } ${className || ''}`}>
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="item">
            <PaymentGateway
              {...d}
              disableName={disableName}
              backgroundWhite={backgroundWhite}
              borderStroke={borderStroke}
              // width="64px"
              // height="64px"
              className={paymentGatewayClass}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
PaymentGatewaySlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      props: PropTypes.shape({}),
    }),
  ),
  className: PropTypes.string,
  paymentGatewayClass: PropTypes.string,
  sameSize: PropTypes.bool,
  disableName: PropTypes.bool,
  backgroundWhite: PropTypes.bool,
  borderStroke: PropTypes.bool,
  settings: PropTypes.shape({}),
};
PaymentGatewaySlider.defaultProps = {
  data: [],
  sameSize: false,
  disableName: true,
  borderStroke: false,
  backgroundWhite: false,
  className: '',
  paymentGatewayClass: 'px-2 py-1',
  settings: {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    draggable: false,
    centerMode: true,
    speed: 1000,
    variableWidth: true,
    cssEase: 'linear',
    // easing: 'linear',
  },
};
export default PaymentGatewaySlider;
