import React from 'react';
import TabPanel from '../components/Tabs/TabPanel';
import CheckList from '../components/CheckList/CheckList';
import PaymentGateway from '../components/common/PaymentGateway/PaymentGateway';
import PaymentGatewaySlider from '../components/Sliders/PaymentGatewaySlider/PaymentGatewaySlider';

const panelFotter = (
  <a
    className="button button-green w-auto px-[24.5px] mt-[32px] md:mt-[40px]"
    href="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_CardlessEMIoptions">
    Create Account <span className="chevron" />
  </a>
);
export const heroLogoData = [
  {
    id: 0,
    src: 'sbi.png',
    width: '74',
    height: '26',
    className: 'max-w-[74px]',
    alt: 'SBI',
  },
  {
    id: 1,
    src: 'hdfc.svg',
    width: '115',
    height: '19',
    className: 'max-w-[115px]',
    alt: 'HDFC',
  },
  {
    id: 2,
    src: 'icici.png',
    width: '108',
    height: '22',
    className: 'max-w-[108px]',
    alt: 'ICICI',
  },
  {
    id: 3,
    src: 'bob.png',
    width: '112',
    height: '51',
    className: 'max-w-[112px]',
    alt: 'bob',
  },
  {
    id: 4,
    src: 'kotak.png',
    width: '103',
    height: '30',
    className: 'max-w-[103px]',
    alt: 'Kotak',
  },
  {
    id: 5,
    src: 'zest.png',
    width: '83',
    height: '25',
    className: 'max-w-[83px]',
    alt: 'Zest',
  },
  {
    id: 6,
    src: 'simpl.png',
    width: '99',
    height: '34',
    className: 'max-w-[99px]',
    alt: 'Simpl',
  },
  {
    id: 7,
    src: 'lazypay.png',
    width: '114',
    height: '19',
    className: 'max-w-[114px]',
    alt: 'Lazypay',
  },
  {
    id: 8,
    src: 'kreditbee.svg',
    width: '114',
    height: '30',
    className: 'max-w-[114px]',
    alt: 'kreditbee',
  },
  {
    id: 9,
    src: 'olamoney.png',
    width: '104',
    height: '35',
    className: 'max-w-[104px]',
    alt: 'Ola',
  },
];
const creditLogoData = [
  { id: 0, type: 'sbi', padding: 12 },
  { id: 1, type: 'hdfc', padding: 14 },
  { id: 2, type: 'icici', padding: 14 },
  { id: 3, type: 'axis', padding: 14 },
  { id: 4, type: 'bob', padding: 11 },
  { id: 5, type: 'kotak' },
  { id: 6, type: 'citi' },
  { id: 7, type: 'hsbc' },
  { id: 8, type: 'yesbank' },
  { id: 9, type: 'rbl', padding: 18 },
  { id: 10, type: 'standardchartered', padding: 16 },
  { id: 11, type: 'auB', padding: 10 },
];
const laterLogoData = [
  { id: 0, type: 'simpl', padding: 14 },
  { id: 1, type: 'hdfc', padding: 14 },
  { id: 2, type: 'lazypaym', padding: 12 },
  { id: 3, type: 'freecharge' },
  { id: 4, type: 'olamoneym' },

  { id: 5, type: 'epaylaterw', padding: 10 },
  { id: 6, type: 'kotak' },
];
export const paymentOptions = [
  {
    id: 0,
    src: '/img/icons/green-growth.svg',
    text: 'Reduce customer drop-offs',
  },
  {
    id: 1,
    src: '/img/icons/purple-diamond.svg',
    text: 'Increase average order value by 40%-60%',
  },
  {
    id: 2,
    src: '/img/icons/magnet-user.svg',
    text: 'Enable easy access to credit',
  },
  {
    id: 3,
    src: '/img/icons/attract-customer.svg',
    text: 'Attract new customer segments',
  },
];
export const otherFeatureData = [
  {
    id: 0,
    icon: '/img/affordability/instant-refunds.svg',
    title: 'Instant Refunds',
    text:
      'Do instant customer refunds - online orders, COD orders and deposits with our advanced refund solutions and delight your customers.',
    link: 'https://www.cashfree.com/instant-refunds/',
  },
  {
    id: 1,
    icon: '/img/affordability/instant-settlements.svg',
    title: 'Instant Settlements',
    text:
      'Get access to your payment gateway collection within 15 minutes even on bank holidays. Say goodbye to cash flow problems.',
    link: 'https://www.cashfree.com/instant-settlements/',
  },
  {
    id: 2,
    icon: '/img/affordability/pre-auth.svg',
    title: 'Pre-authorization',
    text:
      'Temporarily block some amount of funds when a customer places an order and save payment gateway charges on canceled orders with Pre-auth.',
    link: 'https://www.cashfree.com/preauthorization/',
  },
  {
    id: 3,
    icon: '/img/affordability/saved-cards.svg',
    title: 'Token Vault',
    text:
      "Process saved card payments securely with India's first interoperable card tokenization solution.",
    link: 'https://www.cashfree.com/card-tokenization/',
  },
];
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
const imageClassName = 'w-full md:pr-[18px]';
export const featureTabData = [
  {
    key: 0,
    heading: <div className="inline font-body">Cardless EMI options</div>,
    // dropDownHeading: 'Cardless EMI options',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass="mb-4 md:mb-[12px]"
          img={{
            src: '/img/affordability/panel-1.svg',
            width: '610',
            height: '470',
          }}
          heading="Cardless EMI options"
          body={
            <>
              <div className="mb-6 md:mb-8">
                <CheckList
                  data={[
                    {
                      id: 0,
                      text: 'Reach ~25M customers who use Buy Now Pay Later',
                    },
                    {
                      id: 1,
                      text:
                        'Faster checkout through one-click pay later option',
                    },
                    {
                      id: 2,
                      text:
                        'Enable access to credit with just the customer’s phone number',
                    },
                  ]}
                />
              </div>

              <div className="flex justify-start flex-wrap affordability-banking-icon">
                <PaymentGateway
                  type="zestmoneyPng"
                  borderStroke
                  backgroundWhite
                  disableName
                  width="64px"
                  height="64px"
                  padding={10}
                />
                <PaymentGateway
                  borderStroke
                  type="kreditbeeLogo"
                  backgroundWhite
                  disableName
                  width="64px"
                  height="64px"
                  padding={14}
                />
                <PaymentGateway
                  borderStroke
                  type="flexmoney"
                  backgroundWhite
                  disableName
                  width="64px"
                  height="64px"
                  padding={3}
                />
              </div>
            </>
          }
          footer={panelFotter}
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: <h2 className="inline font-body">Debit card EMI</h2>,
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass="mb-4 md:mb-[12px]"
          heading="Debit card EMI"
          img={{
            src: '/img/affordability/panel-2.svg',
            width: '610',
            height: '470',
          }}
          body={
            <>
              <div className="max-w-[500px] mb-4 md:mb-0 pb-6 md:pb-8">
                Expand your business to the untapped customer base by
                introducing the debit card EMI option. Empowering India’s 90+
                crore debit card users is your biggest business opportunity.
              </div>
              <div className="flex justify-start flex-wrap affordability-banking-icon">
                <PaymentGateway
                  type="hdfc"
                  borderStroke
                  backgroundWhite
                  disableName
                  width="64px"
                  padding={14}
                  height="64px"
                />
                <PaymentGateway
                  type="kotak"
                  borderStroke
                  grayscale
                  className="comingsoon-affordability"
                  padding={12}
                  backgroundWhite
                  disableName
                  width="64px"
                  height="64px"
                />
                <PaymentGateway
                  type="icici"
                  borderStroke
                  grayscale
                  backgroundWhite
                  className="comingsoon-affordability"
                  disableName
                  width="64px"
                  height="64px"
                  padding={14}
                />
                <PaymentGateway
                  type="axis"
                  borderStroke
                  grayscale
                  className="comingsoon-affordability"
                  backgroundWhite
                  disableName
                  width="64px"
                  height="64px"
                  padding={12}
                />
              </div>
            </>
          }
          footer={panelFotter}
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: <h2 className="inline font-body">Credit Card EMI</h2>,
    shouldUnmount: true,
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass="mb-4 md:mb-[12px]"
          heading="Credit Card EMI"
          img={{
            src: '/img/affordability/panel-3.svg',
            width: '610',
            height: '470',
          }}
          body={
            <>
              <div className="max-w-[510px] mb-6 md:mb-8">
                Sell your products faster by providing smarter credit card EMI
                options so your customers don’t have to pay you upfront.
                <div className="font-medium text-base mt-4">
                  14+ Banks Supported
                </div>
                <div className="text-tiny">
                  * No cost EMI available for selected partners
                </div>
              </div>
              <div>
                <PaymentGatewaySlider
                  borderStroke
                  className="max-w-[400px]"
                  data={creditLogoData}
                  paymentGatewayClass="px-[12px] py-1"
                  sameSize
                  backgroundWhite
                />
              </div>
            </>
          }
          footer={panelFotter}
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: <h2 className="inline font-body">Flexible Pay Later options</h2>,
    shouldUnmount: true,
    content: (
      <div className="mt-2 md:mt-[60px]">
        <TabPanel
          alignStart
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass="mb-4 md:mb-[12px]"
          heading="Flexible Pay Later options"
          img={{
            src: '/img/affordability/panel-4.svg',
            width: '610',
            height: '470',
          }}
          body={
            <>
              <div className="max-w-[500px] mb-4">
                Provide one-click buy now pay later option to customers through
                our leading Pay Later partners. Let your customers avail the
                convenience of paying after purchase even without a credit or
                debit&nbsp;card.
              </div>
              <div>
                <PaymentGatewaySlider
                  borderStroke
                  className="max-w-[400px]"
                  paymentGatewayClass="px-[12px] py-1"
                  data={laterLogoData}
                  sameSize
                  backgroundWhite
                />
              </div>
            </>
          }
          footer={panelFotter}
        />
      </div>
    ),
  },
];

export const webIntegration = [
  {
    id: 0,
    url: 'https://docs.cashfree.com/docs/api-integration',
    integrationsName: 'RESTful APIs',
  },
  {
    id: 1,
    url: 'https://docs.cashfree.com/docs/api-integration',
    integrationsName: 'Javascript SDK',
  },
];

export const mobileIntegrations = [
  {
    id: 0,
    url: 'https://docs.cashfree.com/docs/android-sdk',
    imgPath: '/img/integrations/android-sdk.png',
    integrationsName: 'Android SDK',
  },
  {
    id: 1,
    url: 'https://docs.cashfree.com/docs/ios-sdk ',
    imgPath: '/img/integrations/ios-sdk.png',
    integrationsName: 'iOS SDK',
  },
  {
    id: 2,
    url: 'https://docs.cashfree.com/docs/react-native-version-2-1-sdk ',
    imgPath: '/img/integrations/react-sdk.svg',
    integrationsName: 'React Native SDK',
  },
  {
    id: 3,
    url: 'https://docs.cashfree.com/docs/flutter-sdk ',
    imgPath: '/img/integrations/flutter-sdk.png',
    integrationsName: 'Flutter SDK',
  },
  {
    id: 4,
    url: 'https://docs.cashfree.com/docs/xamarin-android-support-sdk',
    imgPath: '/img/integrations/xamrian-sdk.png',
    integrationsName: 'Xamarin SDK',
  },
  {
    id: 5,
    url: 'https://docs.cashfree.com/docs/cordova-sdk ',
    imgPath: '/img/integrations/cordova-sdk.svg',
    integrationsName: 'Cordova SDK',
  },
  {
    id: 6,
    url: 'https://docs.cashfree.com/docs/capacitor-sdk ',
    imgPath: '/img/integrations/capacitor.png',
    integrationsName: 'Capacitor SDK',
  },
];

export const languageSupported = [
  {
    id: 0,
    url: '#!',
    integrationsName: 'PHP',
  },
  {
    id: 1,
    url: '#!',
    integrationsName: 'Java',
  },
  {
    id: 2,
    url: '#!',
    integrationsName: 'Go',
  },
  {
    id: 3,
    url: '#!',
    integrationsName: 'Python',
  },
  {
    id: 4,
    url: '#!',
    integrationsName: 'C#',
  },
];

export const plugins = [
  {
    id: 0,
    url: 'https://docs.cashfree.com/docs/shopify',
    imgPath: '/img/integrations/shopify.png',
    integrationsName: 'Shopify',
  },
  {
    id: 1,
    url: 'https://docs.cashfree.com/docs/wix',
    imgPath: '/img/integrations/wix.png',
    integrationsName: 'Wix',
  },
  {
    id: 2,
    url: 'https://docs.cashfree.com/docs/woocommerce',
    imgPath: '/img/integrations/woocommerce.png',
    integrationsName: 'WooCommerce',
  },
  {
    id: 3,
    url: 'https://docs.cashfree.com/docs/magento',
    imgPath: '/img/integrations/magneto.png',
    integrationsName: 'Magento',
  },
  {
    id: 4,
    url: 'https://docs.cashfree.com/docs/prestashop',
    imgPath: '/img/integrations/prestashop.png',
    integrationsName: 'PrestaShop',
  },

  {
    id: 5,
    url: 'https://docs.cashfree.com/docs/whmcs',
    imgPath: '/img/integrations/whmcs.png',
    integrationsName: 'WHMCS',
  },
  {
    id: 6,
    url: 'https://docs.cashfree.com/docs/cs-cart',
    imgPath: '/img/integrations/cs-cart.svg',
    integrationsName: 'CS Cart',
  },
  {
    id: 7,
    url: 'https://docs.cashfree.com/docs/opencart',
    imgPath: '/img/integrations/opencart.png',
    integrationsName: 'OpenCart',
  },
  {
    id: 8,
    url: 'https://docs.cashfree.com/docs/wordpress',
    imgPath: '/img/integrations/wordpress.png',
    integrationsName: 'Wordpress',
  },
  {
    id: 9,
    url: 'https://docs.cashfree.com/docs/zoho',
    imgPath: '/img/integrations/zohobook.png',
    integrationsName: 'Zoho Books',
  },
];

export const faqData = [
  {
    id: 0,
    q:
      'What are the different payment modes Cashfree Payments offers as a part of Buy Now Pay Later?',
    a: (
      <>
        <p>
          Cashfree Payments hosts a wide range of alternate payment modes for
          businesses to increase sales without the need for any upfront payment
          from customers. Here are some of the <b>Buy Now Pay Later</b> payment
          modes supported:
        </p>
        <ul className="list-decimal">
          <li>
            <b>Debit card EMI</b> - Available on multiple banks including HDFC
            Bank and ICICI Bank
          </li>
          <li>
            <b>Credit card EMI</b> - Available on 8+ banks including HDFC Bank,
            ICICI Bank, Axis Bank, Bank Of Baroda, Kotak Mahindra Bank, Standard
            Chartered, RBL Bank, AU Small Finance Bank among others
          </li>
          <li>
            <b>Cardless EMI</b> - Available on multiple banks including HDFC
            Bank, ICICI Bank, Federal Bank, Kotak Bank, IDFC First Bank,
            ZestMoney, Kreditbee among others
          </li>
          <li>
            <b>Buy Now Pay Later</b> - Available on HDFC, LazyPay, Ola Money
            Postpaid, ePayLater, Simpl, Kotak and more
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 1,
    q:
      'What is the difference between Cardless EMI and Credit card EMI or Debit card EMI?',
    a: (
      <>
        <p>
          The credit card EMI or debit card EMI option requires the customers to
          purchase a product from your website based on their credit or debit
          card validation. Customers are expected to enter their corresponding
          card details and verify it in order to avail the EMI option.
        </p>
        <p>
          Cardless EMI, on the other hand, requires only the customer phone
          number and not the card details. Being a completely digital payment
          method, customers can purchase a product and pay for it in EMI without
          the need of a credit or a debit card. Most suitable for high-valued
          goods like mobile phones, laptops or furniture.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'How is Pay Later different from Cardless EMI?',
    a: (
      <>
        <p>
          Pay Later option comes with a virtual card that allows customers to
          purchase the products with zero down payment. Customers can pay the
          amount later, within a specified date without any interest fee.
        </p>
        <p>
          Whereas, Cardless EMI option is a completely digital payment mode
          where the customer need not enter any credit or debit card details. It
          allows repayment in installments of 3, 6, 9, 12 months with specific
          interest rates.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'Who is eligible for availing BNPL options for their business?',
    a: (
      <>
        <p>
          All merchants, except those in the restricted category of business,
          can provide the BNPL options to their customers on their website or
          app checkout. While the majority of the merchants are auto-approved,
          some businesses are subject to approval from the BNPL&nbsp;partners.
        </p>
        <p>
          Contact{' '}
          <a href="mailto:care@cashfree.com" className="email-link">
            care@cashfree.com
          </a>{' '}
          for more information.
        </p>
      </>
    ),
  },
  {
    id: 4,
    q:
      'I am using Cashfree Payment Gateway. How can I add Buy Now Pay Later options to my checkout page?',
    a: (
      <>
        <p>
          Visit your Cashfree Merchant Dashboard to see all the Buy Now Pay
          Later options available.
        </p>
        <p>
          <b>Credit Card EMI</b>: Enabled by default for all banks except SBI,
          HSBC, Yes Bank, Citi, IndusInd Bank and Federal Bank that require the
          merchant to contact their Account Manager for&nbsp;activation.
        </p>
        <p>
          <b>Cardless EMI</b>: Kreditbee is activated by default. Other Cardless
          EMI options are enabled on&nbsp;request.
        </p>
        <p>
          <b>Pay Later</b>: OlaMoney Postpaid is enabled by default. Other
          PayLater options are enabled on&nbsp;request.
        </p>
        <p>
          For more information write to{' '}
          <a href="mailto:care@cashfree.com" className="email-link">
            care@cashfree.com
          </a>{' '}
        </p>
      </>
    ),
  },

  {
    id: 6,
    q:
      'I am a Cashfree Payment Gateway merchant who uses the Seamless checkout flow. How can I integrate the Buy Now Pay Later options with my website or mobile app?',
    a: (
      <>
        <p>
          If you are a merchant using our Seamless checkout flow, you can easily
          integrate the Buy Now Pay Later options on your website or mobile
          checkout with a few additional parameters on the existing{' '}
          <a
            href="https://docs.cashfree.com/reference/orderpay"
            target="_blank"
            className="external-link"
            rel="noreferrer">
            APIs
          </a>{' '}
          .Visit the Developers section in the Cashfree Merchant dashboard and
          use the test API keys to try these out.
        </p>
        <p>
          Relevant T&Cs and logos of each payment partner would need to be added
          on your payment page as required.
        </p>
      </>
    ),
  },
  {
    id: 7,
    q:
      'I sell my products/services to customers in multiple countries, can I offer BNPL modes?',
    a: (
      <p>
        EMI via International cards like Amex are supported for payments outside
        of India.
      </p>
    ),
  },
  {
    id: 8,
    q:
      'Are there any late fee charges to my customers if they default on EMI or PayLater?',
    a: (
      <p>
        Late fee charges for the end customers are applicable as per the terms
        and conditions of the EMI or PayLater partner.
      </p>
    ),
  },
  {
    id: 9,
    q:
      'Are there any hidden charges for merchants who request to activate any of the BNPL modes?',
    a: (
      <p>
        There are no hidden charges for merchants for activating any of the BNPL
        modes. The pricing is transparent.
      </p>
    ),
  },
];

export const otherProducts = [
  {
    id: 0,
    title: 'Recurring Payments',
    body:
      'Auto-debit funds for periodic payments via cards, UPI and net banking.',
    url: 'https://www.cashfree.com/recurring-payment',
    iconname: '/img/icons/recurring-payments-2.svg',
  },
  {
    id: 1,
    title: 'Payment Gateway',
    body: 'Accept domestic and international payments for your website or app.',
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname: '/img/icons/payment-gateway-2.svg',
  },
  {
    id: 2,
    title: 'Easy Split',
    body: 'Split vendor commissions and manage marketplace\xa0settlements.',
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway',
    iconname: '/img/icons/easy-split-2.svg',
  },
  {
    id: 3,
    title: 'Auto Collect',
    body:
      'Collect and reconcile IMPS/NEFT/UPI payments using unique virtual accounts and UPI\xa0IDs.',
    url: 'https://www.cashfree.com/auto-e-collect',
    iconname: '/img/icons/auto-collect-3.svg',
  },
];
