import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './HeroLogoSlider.scss';

function HeroLogoSlider({ imgUrl, data, settings, className, itemClassName }) {
  return (
    <div
      className={`hero-logo-slider overflow-hidden pointer-events-none ${
        className || ''
      }`}>
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="item">
            <div
              className={
                `${itemClassName || ''} ${d.className || ''}` || 'max-w-[80px]'
              }>
              <img
                className="w-full"
                src={`${imgUrl}/${d.src}`}
                alt={d.alt}
                width={d.width}
                height={d.height}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
HeroLogoSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
  imgUrl: PropTypes.string,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  settings: PropTypes.shape({}),
};
HeroLogoSlider.defaultProps = {
  data: [],
  className: '',
  itemClassName: '',
  imgUrl: '',
  settings: {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    draggable: false,
    centerMode: true,
    speed: 1000,
    variableWidth: true,
    cssEase: 'linear',
    // easing: 'linear',
  },
};
export default HeroLogoSlider;
