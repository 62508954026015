import { useLayoutEffect, useState } from 'react';
import debounce from '../utils/debounce';

const useWindowSize = () => {
  const [size, setSize] = useState(
    typeof window !== 'undefined'
      ? { width: window.innerWidth, height: window.innerHeight }
      : { width: 0, height: 0 },
  );

  useLayoutEffect(() => {
    const handleWindowResize = debounce(() => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    });
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { size };
};
export default useWindowSize;
