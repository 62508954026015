import React from 'react';
import Layout from '../components/Layout';
import Scrollspy from '../components/Scrollspy';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import FriendlyPricingAccordion from '../components/common/PricingAccordion/FriendlyPricingAccordion';
import FAQ from '../components/FAQ';
import OtherProducts from '../components/OtherProducts';
import GetStarted from '../components/GetStarted';
import EnterpriseContainer from '../components/common/EnterprisePricing/EnterpriseContainer';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import {
  heroLogoData,
  paymentOptions,
  otherFeatureData,
  featureTabData,
  webIntegration,
  mobileIntegrations,
  languageSupported,
  plugins,
  otherProducts,
  faqData,
} from '../content/affordability-suite';
import '../styles/affordability-suite.scss';

function AffordabilitySuitePage({ ...rest }) {
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=affordability%20suite&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Offer Buy Now Pay Later modes | Cashfree Payments"
        description="Cashfree Buy Now Pay Later Suite (BNPL) helps businesses increase customer conversions by providing flexible payment options. Offer debit card emi, credit card emi, cardless emi and pay later."
        url="https://www.cashfree.com/buy-now-pay-later/"
        keywords={[
          'Buy Now Pay Later',
          'Debit card EMI',
          'buy now pay later',
          'Credit Card EMI',
          'Cardless emi',
        ]}
      />

      <section className="relative bg-cf-primary affordability-hero-section overflow-hidden text-white">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap mb-[36px] md:mb-[70px] hero-container">
            <div className="relative z-[1] w-full lg:w-1/2 mb-8 lg:mb-0 lg:self-center">
              <h1 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Buy Now Pay Later
              </h1>
              <span className="mb-5 text-xl md:text-2xl font-semibold  font-heading block">
                India&apos;s widest range of{' '}
                <h2 className="inline">Buy Now Pay Later options</h2>
              </span>
              <p className="text-opacity-80 mb-[30px] max-w-[600px] text-[16px] leading-[24px] md:text-2.5md">
                Provide flexible payment options with card and{' '}
                <h2 className="inline font-body">cardless EMI</h2>,{' '}
                <h2 className="inline font-body">Pay Later</h2> and increase
                customer conversions by 30%.
              </p>

              <div className="flex flex-wrap flex-col md:flex-row md:flex-nowrap md:justify-start mb-[50px] md:mb-0">
                <a
                  className="button button-green w-full md:w-auto md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto mt-4 md:mt-0"
                  href="https://www.cashfree.com/contact-sales?source-action=affordability%20suite&action=Contact%20Sales&button-id=ContactSales_Hero">
                  Contact Sales <span className="chevron !mb-0" />
                </a>
              </div>
              <div className="logos">
                <div className="row justify-flex-start marquee marquee-small ">
                  <span>
                    <img
                      src="/img/easemytrip-light.svg"
                      alt="Easemytrip"
                      width="90"
                      height="30"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/livspace-light.svg"
                      alt="LIVSPACE"
                      width="103"
                      height="30"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/noise-light.svg"
                      alt="Noise"
                      width="90"
                      height="26"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/vedantu.png"
                      alt="Vedantu"
                      width="81"
                      height="22"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/easemytrip-light.svg"
                      alt="Easemytrip"
                      width="90"
                      height="30"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/livspace-light.svg"
                      alt="LIVSPACE"
                      width="103"
                      height="30"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/noise-light.svg"
                      alt="Noise"
                      width="90"
                      height="26"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/vedantu.png"
                      alt="Vedantu"
                      width="81"
                      height="22"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 ">
              <div className="relative z-10 w-full">
                <video
                  autoPlay
                  muted
                  playsInline
                  loop
                  className="inset-video relative z-10 chrome-video"
                  width="1176"
                  height="884">
                  <source
                    src="/img/affordability/hero-video.webm"
                    type="video/webm"
                  />
                  <source
                    src="/img/affordability/hero-video_H264.mp4"
                    type="video/mp4"
                  />
                </video>
                <video
                  autoPlay
                  muted
                  playsInline
                  loop
                  className="inset-video hidden safari-video relative z-10"
                  width="1176"
                  height="884">
                  <source
                    src="/img/affordability/hero-video_H264.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-container">
          <div className="container">
            <div className="mb-[18px] md:mb-10 text-center relative z-[1] text-2md font-semibold">
              30+ supported banks & partners
            </div>

            <div className="relative overflow-hidden z-[1]">
              <HeroLogoSlider
                data={heroLogoData}
                imgUrl="/img/affordability/logos"
                itemClassName="mx-[25px] flex items-center align-center h-[50px]"
                settings={{
                  dots: false,
                  arrows: false,
                  infinite: true,
                  autoplay: true,
                  autoplaySpeed: 0,
                  pauseOnHover: false,
                  draggable: false,
                  centerMode: true,
                  speed: 2000,
                  variableWidth: true,
                  rtl: true,
                  cssEase: 'linear',
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <Scrollspy
        centeredLinks
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/buy-now-pay-later.svg"
              alt="affordability-icon"
              width="33"
              height="23"
              className="relative -top-[2px]"
            />
            <span>Buy Now Pay Later Suite</span>
          </div>
        }>
        <section
          data-spy-title="Payment Options"
          data-spy-id="paymentOptions"
          className="">
          <div className="pt-[48px] md:pt-[128px] relative">
            <div className="container">
              <div className="flex flex-wrap flex-col-reverse md:flex-row items-center">
                <div className="w-full md:w-1/2 md:pr-[19px] mt-8 md:mt-0">
                  <img
                    className="w-full"
                    src="/img/affordability/payment-options.svg"
                    alt=""
                    width="610"
                    height="514"
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-[19px]">
                  <div className="font-heading text-lg md:text-xl font-semibold text-cf-hero max-w-[500px] mb-8">
                    Unlock more revenue with Buy Now Pay Later
                  </div>
                  <ul>
                    {paymentOptions.map((o) => (
                      <li
                        key={o.id}
                        className="flex justify-start mb-7 last:mb-0 md:mb-8 items-center">
                        <div className="w-[30px]">
                          <img src={o.src} alt="" className="w-full" />
                        </div>
                        <div className="pl-4 text-[16px] leading-[24px] font-medium">
                          {o.text}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <a
                    className="button button-green w-full md:w-auto px-[24.5px] mt-7 md:mt-11"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_AffordabilitySuite">
                    Create Account <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>

            <div className="bg-white mt-[48px] md:mt-0 pt-[48px] md:pt-[128px] pb-[48px] md:pb-[128px]">
              <div className="container pay-options">
                <div className="text-shs md:text-shl font-semibold max-w-[495px]  mb-6 md:mb-8 font-heading">
                  Comprehensive BNPL Suite
                </div>
                <Tabs data={featureTabData} dropDown timerAutoPlay />
              </div>
            </div>
          </div>
        </section>
        <section className="relative bg-cf-primary md:bg-white overflow-hidden md:rounded mb-[48px] md:mb-[128px]">
          <div className="container relative  md:rounded md:bg-cf-primary overflow-hidden">
            <div className="flex flex-wrap flex-col-reverse lg:flex-row justify-end affordability-offer-section py-[48px] md:pt-[43px] md:pb-[62px]">
              <div className="w-full lg:w-4/12 xl:w-1/2 pt-[32px] lg:pt-0 self-end mb-[-50px] md:mb-[-62px]">
                <img
                  className="relative w-4/5 mx-auto max-w-[425px]"
                  src="/img/affordability/special-offer.svg"
                  width="425"
                  height="330"
                  alt=""
                />
              </div>
              <div className="w-full lg:w-8/12 xl:w-1/2 lg:pl-[30px]  lg:self-center text-white relative z-[1]">
                <div className="mb-5 text-4md md:text-2lg font-semibold font-heading max-w-[500px]">
                  Provide No Cost EMI and special offers
                </div>
                <div className="mb-6 text-cf-light-cold-purple text-2.5sm md:text-md max-w-[500px]">
                  Delight your customers through No Cost EMI on credit cards and
                  running seasonal offers through coupon based discounts and
                  cashbacks on your checkout page.
                </div>
                <a
                  className="button button-green w-auto px-[24.5px] mb-8 md:mb-0"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_StartNow_ProvideNoCostEMI">
                  Create Account <span className="chevron" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          data-spy-title="Integration"
          data-spy-id="integration"
          className="overflow-hidden">
          <div className="p-section-2 bg-cf-dark power-integrations-section m16">
            <div className="container z-10 relative">
              <p className="text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto hidden md:inline-block text-white">
                POWERFUL INTEGRATIONS
              </p>
              <div className="text-shs md:text-shl font-semibold text-white max-w-[830px] mb-[2px] md:mb-[42px] font-heading">
                Go live in quick time with our customized payment gateway
                integrations and&nbsp;
                <span className="whitespace-nowrap">plug-ins</span>
              </div>
              <div className="flex flex-col-reverse md:flex-col">
                <div>
                  <a
                    href="https://docs.cashfree.com/docs/payment-gateway"
                    className="button button-green px-[28px] w-full md:w-auto mt-[22px] md:mt-0">
                    View Developer Documentation <span className="chevron" />
                  </a>
                </div>
                <div>
                  <h3 className="small-title text-white mt-8 md:mt-[58px] mb-2 md:mb-0">
                    Web Integrations
                  </h3>
                  <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                    {webIntegration.map(({ id, integrationsName, url }) => (
                      <a
                        href={url}
                        key={id}
                        className="single-integration m16 text-block">
                        <span>{integrationsName}</span>
                      </a>
                    ))}
                  </div>

                  <h3 className="small-title text-white mt-[40px] mb-2 md:mb-0">
                    Mobile Integrations
                  </h3>
                  <div className="row row-no-margin justify-flex-start flex-wrap integrations-block max-w-[900px]">
                    {mobileIntegrations.map(
                      ({ id, imgPath, integrationsName, url }) => (
                        <a href={url} key={id} className="single-integration">
                          <div className="integration-image">
                            <img
                              src={imgPath}
                              alt={integrationsName}
                              loading="lazy"
                            />
                          </div>
                          <span>{integrationsName}</span>
                        </a>
                      ),
                    )}
                  </div>
                  <h3 className="small-title text-white mt-8 md:mt-[58px] mb-2 md:mb-0">
                    Languages supported
                  </h3>
                  <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                    {languageSupported.map(({ id, integrationsName, url }) => (
                      <a
                        href={url}
                        key={id}
                        className="single-integration text-block ">
                        <span>{integrationsName}</span>
                      </a>
                    ))}
                  </div>
                  <h3 className="small-title text-white mt-[40px] mb-2 md:mb-0">
                    Plugins
                  </h3>
                  <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                    {plugins.map(({ id, imgPath, integrationsName, url }) => (
                      <a href={url} key={id} className="single-integration">
                        <div className="integration-image">
                          <img
                            src={imgPath}
                            alt={integrationsName}
                            loading="lazy"
                          />
                        </div>
                        <span>{integrationsName}</span>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-spy-title="Pricing"
          data-spy-id="pricing"
          className="p-section-2 bg-cf-light-grey">
          <div className="container text-center md:text-left ">
            <div className="text-center">
              <p className="text-cf-primary text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block">
                PRICING
              </p>
              <div className="text-shs md:text-shl font-semibold mb-6 md:mb-8 font-heading mx-auto">
                Transparent and friendly pricing
              </div>
              <a
                className="button button-green w-auto px-[24.5px] mb-8 md:mb-16"
                href="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_Pricing">
                Create Account <span className="chevron" />
              </a>
            </div>
            <EnterpriseContainer
              component={<FriendlyPricingAccordion />}
              link="https://www.cashfree.com/contact-sales?source-action=affordability%20suite&action=Contact%20Sales&button-id=ContactSales_PricingEnterprises"
            />
          </div>
        </section>
        <section className="p-section-2 bg-cf-dark text-white other-features-section small-image overflow-hidden">
          <div className="container relative z-10">
            <p className="text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block">
              THERE’s MORE
            </p>
            <div className="text-shs md:text-shl font-semibold max-w-[610px] mb-[32px] font-heading">
              Get access to advanced payment gateway features
            </div>
            <div className="flex flex-col-reverse md:flex-col">
              <a
                className="button button-green w-auto px-[24.5px] mt-5 md:mt-0 md:mb-[60px] md:max-w-[178px]"
                href="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_Theresmore">
                Create Account <span className="chevron" />
              </a>

              <div className="flex flex-wrap flex-row -mx-3 -mt-3 md:mx-[-19px] md:mt-[-19px]">
                {otherFeatureData.map((f) => (
                  <div
                    className="w-1/2 lg:w-1/4 self-stretch p-3 md:p-[19px] text-2.5sm md:text-md "
                    key={f.id}>
                    <div className="md:hover:scale-105 transition-all duration-300 bg-white bg-opacity-[0.07] h-full  rounded border border-opacity-10 border-white">
                      <div className="py-6 px-2 md:px-6 md:py-8 h-full rounded flex flex-col justify-between">
                        <div>
                          <div className="w-[32px] h-[32px] py-[2px] md:py-0 md:w-[40px] md:h-[40px] flex justify-center items-center">
                            <img
                              src={f.icon}
                              alt={f.title}
                              loading="lazy"
                              className="w-full h-full"
                            />
                          </div>
                          <div className="font-semibold pt-6 pb-3 text-2md">
                            {f.title}
                          </div>
                          <p className="text-cf-cold-purple font-normal">
                            {f.text}
                          </p>
                        </div>
                        <a
                          href={f.link}
                          className="button button-unstyled btn pt-[35px] p-0 !text-cf-green group left-[83px] !right-auto">
                          Learn More
                          <svg
                            className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L5 5.5L1 10"
                              stroke="#05C16E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Scrollspy>

      <section className="container general-faqs">
        <FAQ
          supportLinkHref="https://www.cashfree.com/help/hc"
          open="0"
          data={faqData}
        />
      </section>
      <OtherProducts data={otherProducts} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=affordability%20suite&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=affordability%20suite&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}
export default AffordabilitySuitePage;
