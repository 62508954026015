import React from 'react';
import PropTypes from 'prop-types';

function AccordionHead({ heading, subHeading, type }) {
  return (
    <div className="flex justify-between w-full pr-[8px] ">
      {type === 'span' ? (
        <span>{heading}</span>
      ) : (
        <h2 className="inline font-body">{heading}</h2>
      )}
      <span className="text-cf-primary">{subHeading}</span>
    </div>
  );
}

AccordionHead.defaultProps = {
  heading: 'heading',
  subHeading: 'subHeading',
  type: 'span',
};
AccordionHead.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
};

export default AccordionHead;
