import React from 'react';
import Accordion from '../../Accordion/Accordion';
import PaymentGateway from '../PaymentGateway/PaymentGateway';
import AccordionHead from './AccordionHead';

const paymentProps = {
  className: 'w-[48px] md:w-[90px] md:mb-[40px] my-[4px] mx-[16px]',
  backgroundWhite: true,
  border: true,
  textSize: 'text-tiny md:text-2.5sm',
};
const borderStyle = {
  backgroundImage: 'linear-gradient(#DCDEE6 50%, rgba(255,255,255,0) 0%)',
  backgroundPosition: 'right 7px, center',
  backgroundSize: '1.5px 8px',
  backgroundRepeat: 'repeat-y',
};
const pricingAccordianData = [
  {
    key: '0',
    heading: (
      <AccordionHead
        heading="Credit Card EMI"
        subHeading="TDR+0.25%"
        type="span"
      />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0 justify-center sm:justify-start">
          <PaymentGateway type="hdfc" {...paymentProps} />
          <PaymentGateway type="kotak" {...paymentProps} />
          <PaymentGateway type="icici" {...paymentProps} />
          <PaymentGateway type="rbl" {...paymentProps} />
          <PaymentGateway type="bob" {...paymentProps} />
          <PaymentGateway type="standardchartered" {...paymentProps} />
          <PaymentGateway type="axis" {...paymentProps} />
          <PaymentGateway type="auB" padding={8} {...paymentProps} />
        </div>
      </div>
    ),
  },
  {
    key: '1',
    heading: (
      <AccordionHead
        heading="Debit Card EMI"
        subHeading="1.50%"
        type="heading"
      />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap md:mx-[-21px] py-3">
          <div className="pr-4" style={borderStyle}>
            <PaymentGateway
              type="hdfc"
              className="w-[65px] md:w-[90px]"
              textSize={paymentProps.textSize}
            />
          </div>
          <div className="flex-1 self-center ml-6 font-medium text-[12px] leading-6">
            Other major banks coming soon
          </div>
        </div>
      </div>
    ),
  },
  {
    key: '2',
    heading: (
      <AccordionHead heading="Cardless EMI" subHeading="1.90%" type="heading" />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0 justify-center sm:justify-start">
          <PaymentGateway type="hdfc" {...paymentProps} />
          <PaymentGateway type="icici" {...paymentProps} />
          <PaymentGateway type="kotak" {...paymentProps} />
          <PaymentGateway type="idfc" {...paymentProps} />
          <PaymentGateway type="federalbank" {...paymentProps} />
          <PaymentGateway type="homecredit" {...paymentProps} />
          <PaymentGateway type="zestmoneyPng" {...paymentProps} />
          <PaymentGateway type="kreditbeeLogo" {...paymentProps} />
        </div>
      </div>
    ),
  },
  {
    key: '3',
    heading: (
      <AccordionHead
        heading="Pay Later"
        type="heading"
        subHeading={
          <>
            <span className="text-cf-faded-black inline-block line-through text-sm font-medium mr-2.5">
              2.50%
            </span>
            2.20%
          </>
        }
      />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0 justify-center sm:justify-start">
          <PaymentGateway type="lazypaym" padding={10} {...paymentProps} />
          <PaymentGateway
            type="olamoneym"
            text="Ola Money Postpaid"
            {...paymentProps}
          />
          <PaymentGateway type="epaylaterw" text="ePay" {...paymentProps} />
          <PaymentGateway
            type="hdfc"
            text="HDFC via Flexmoney"
            {...paymentProps}
          />
          <PaymentGateway
            type="kotak"
            text="Kotak via Flexmoney"
            {...paymentProps}
          />
          <PaymentGateway type="simpl" {...paymentProps} />
          <PaymentGateway
            type="freecharge"
            text="Freecharge PayLater"
            {...paymentProps}
          />
          <PaymentGateway
            type="zestmoneyPng"
            text="Zest Money PayLater"
            {...paymentProps}
          />
        </div>
      </div>
    ),
  },
];
function FriendlyPricingAccordion() {
  return (
    <>
      <Accordion
        data={pricingAccordianData}
        type="box"
        // tabClassName="lightGrey"
      />
      <small className="italic text-tiny pt-[12px]  pr-1 font-normal block text-left">
        * Charges applicable per transaction
      </small>
    </>
  );
}

export default FriendlyPricingAccordion;
