import PropTypes from 'prop-types';
import React from 'react';
import EnterprisePricing from './EnterprisePricing';

function EnterpriseContainer({ component, link }) {
  return (
    <div className="flex flex-row flex-wrap justify-center">
      <div className="w-full md:w-3/4 lg:w-1/2 lg:mr-[38px]">{component}</div>
      <div className="w-full md:w-3/4 lg:w-[400px]">
        <EnterprisePricing link={link} />
      </div>
    </div>
  );
}
EnterpriseContainer.propTypes = {
  component: PropTypes.node,
  link: PropTypes.string,
};
EnterpriseContainer.defaultProps = {
  component: null,
  link: '!#',
};
export default EnterpriseContainer;
