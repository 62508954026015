/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({
  img,
  imageClassName,
  contentClassName,
  heading,
  headingClass,
  body,
  footer,
  alignStart,
  mobileVisibleHeading,
  headingType,
  isVideo,
}) => {
  const headingClassNames = `panel-heading text-3md md:text-shs font-semibold text-cf-hero max-w-[500px] ${headingClass} font-body ${
    mobileVisibleHeading ? 'block' : 'hidden md:block'
  }`;
  return (
    <div
      className={`flex flex-wrap justify-between ${
        alignStart ? 'items-start' : ' items-center'
      } flex-col-reverse md:flex-row panel`}>
      <div className="w-full md:w-1/2 mt-[24px] md:mt-0">
        {isVideo ? (
          <video
            autoPlay
            loop
            muted
            playsInline
            src={img.src}
            width={img.width}
            height={img.height}
            placeholder={img?.placeholder || ''}
            className={imageClassName}
          />
        ) : (
          <img
            src={img.src}
            alt=""
            width={img.width}
            height={img.height}
            className={imageClassName}
          />
        )}
      </div>
      <div className={`w-full md:w-6/12 ${contentClassName}`}>
        {headingType === 'heading' ? (
          <h2 className={headingClassNames}>{heading}</h2>
        ) : (
          <div className={headingClassNames}>{heading}</div>
        )}
        <div className="text-2.5sm md:text-md">{body}</div>
        {footer}
      </div>
    </div>
  );
};
TabPanel.defaultProps = {
  img: {
    src: '',
    width: '',
    height: '',
  },
  imageClassName: '',
  contentClassName: 'md:p-[20px] mt-0',
  heading: '',
  headingClass: 'mb-[32px]',
  body: '',
  mobileVisibleHeading: true,
  alignStart: false,
  footer: '',
  headingType: 'heading',
  isVideo: false,
};
TabPanel.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  mobileVisibleHeading: PropTypes.bool,
  imageClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  alignStart: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headingClass: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headingType: PropTypes.oneOf(['heading', 'div']),
  isVideo: PropTypes.bool,
};
export default TabPanel;
