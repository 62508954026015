import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Accordion.scss';
import Icon from '../Icon';
import useWindowSize from '../../hooks/useWindowSize';

function Accordion({
  data,
  spacing,
  type,
  alwaysOpen,
  arrow,
  tabClassName,
  className,
  border,
  colored,
  arrowClassName,
  defaultOpen,
  buttonClassName,
  contentClassName,
}) {
  const {
    size: { width },
  } = useWindowSize();
  const [activeTab, setActiveTab] = useState(
    defaultOpen && data.length ? data[0]?.key : '',
  );
  const activeClass = (key) => `${(activeTab === key && 'active') || ''}`;
  const onClick = (key) => {
    if (alwaysOpen) {
      setActiveTab(key);
    } else {
      setActiveTab((prevKey) => (prevKey === key ? null : key));
    }
  };
  useEffect(() => {
    if (!defaultOpen && !alwaysOpen) {
      if (width > 768) {
        setActiveTab(data.length ? data[0]?.key : '');
      } else {
        setActiveTab('');
      }
    }
  }, [width]);
  return (
    <div
      className={`accordion-container ${type} ${className} ${
        border ? 'hasBorder' : ''
      } ${colored ? 'colored' : ''}`}>
      {data.map((d) => (
        <div
          key={d.key}
          className={`tab text-left overflow-hidden w-full ${activeClass(
            d.key,
          )} ${spacing} ${tabClassName}`}>
          <button
            className={`button-unstyled w-full flex flex-row justify-between text-2.5sm md:text-md accordion-heading font-semibold font-body text-cf-hero ${buttonClassName}`}
            type="button"
            onClick={() => onClick(d.key)}
            tabIndex={0}>
            {d.heading}
            {arrow === 'chevron' && (
              <Icon
                name="chevron"
                width="11px"
                className={`self-center flex-shrink-0 ${arrowClassName}`}
                rotate={activeTab === d.key ? '180deg' : '0deg'}
              />
            )}
            {arrow === 'chevron-current' && (
              <Icon
                name="chevron-current"
                width="11px"
                className={`self-center flex-shrink-0 ${
                  activeTab !== d.key ? 'text-cf-icon-light' : ''
                }
                ${arrowClassName}`}
                rotate={activeTab === d.key ? '180deg' : '0deg'}
              />
            )}
            {arrow === 'plus' && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                className={
                  activeTab !== d.key
                    ? `text-cf-icon-light opacity-60 flex-shrink-0 ml-3md ${arrowClassName}`
                    : `text-cf-icon-light opacity-60 flex-shrink-0 ml-3md ${arrowClassName}`
                }
                xmlns="http://www.w3.org/2000/svg">
                <path
                  className={`transition-all duration-300 origin-center ${
                    activeTab === d.key ? 'rotate-90' : ''
                  }`}
                  d="M6 1.35714V10.6429"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 6H11"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
          <div
            className={`accordion-content ${contentClassName} ${activeClass(
              d.key,
            )}`}>
            {d.content}
          </div>
        </div>
      ))}
    </div>
  );
}
Accordion.defaultProps = {
  type: 'default',
  arrow: 'chevron',
  className: '',
  arrowClassName: '',
  data: [],
  tabClassName: '',
  spacing: '',
  alwaysOpen: false,
  border: false,
  colored: false,
  defaultOpen: true,
  buttonClassName: '',
  contentClassName: '',
};
Accordion.propTypes = {
  type: PropTypes.oneOf(['default', 'box']),
  arrow: PropTypes.oneOf(['chevron', 'chevron-current', 'plus']),
  arrowClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  className: PropTypes.string,
  border: PropTypes.bool,
  colored: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  alwaysOpen: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  spacing: PropTypes.string,
  buttonClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};
export default Accordion;
